import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Markdown from "../common/markdown";
import SvgIcon from "../common/svg-icon";
import GoogleTag from "../../utils/google-tag";

const CELL_HEIGHT = "300";

const TeamCell = ({ member, className }) => (
    <div className={className}>
        <div className="image-content">
            <img src={member.image ? member.image.url : null} alt={member.fullName} />
        </div>
        <div className="team-cell-content">
            <div>
                <h1 className="team-cell-name">{member.fullName}</h1>
                <h5 className="team-cell-title">{member.role}</h5>
                <div className="team-cell-info">
                    <Markdown value={member.summary} />
                </div>
                <div className="team-cell-links">
                    {member.linkedin ? (
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            onClick={() => GoogleTag.handle(`click_linkedin_${member.fullName}`)}
                            href={member.linkedin}
                        >
                            <SvgIcon icon="linkedin" />
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    </div>
);

TeamCell.propTypes = {
    member: PropTypes.shape({
        id: PropTypes.string,
        role: PropTypes.string,
        fullName: PropTypes.string,
        summary: PropTypes.string,
        linkedin: PropTypes.string,
        image: PropTypes.shape({
            url: PropTypes.string,
        }),
    }).isRequired,
    className: PropTypes.string.isRequired,
};

export default styled(TeamCell)`
    position: relative;
    margin-bottom: 40px;
    padding: 0;
    display: flex;
    align-items: flex-start;
    img {
        flex-grow: 0;
        width: ${CELL_HEIGHT}px;
        min-width: ${CELL_HEIGHT}px;
        height: ${CELL_HEIGHT}px;
        min-height: ${CELL_HEIGHT}px;
    }

    @media only screen and (max-width: ${({ theme }) => theme.contentWidth}) {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5em;
        display: block;
        .image-content {
            width: 100%;
            text-align: center;
        }
        img {
            width: ${CELL_HEIGHT / 3}px;
            height: ${CELL_HEIGHT / 3}px;
            min-width: ${CELL_HEIGHT / 3}px;
            min-height: ${CELL_HEIGHT / 3}px;
            overflow: hidden;
            border-radius: 50%;
        }
        .team-cell-links {
            position: absolute;
            top: 0;
            left: 70%;
        }
    }
    .team-cell-content {
        flex-grow: 1;
        margin-left: 20px;

        h1 {
            margin-bottom: 0;
            margin-top: 0;
        }
        h5 {
            font-size: 1.2em;
            margin-bottom: 1em;
        }
        @media only screen and (max-width: ${({ theme }) => theme.contentWidth}) {
            left: 120px;
            text-align: center;
            margin-left: 0;
            h1 {
                margin-bottom: 0;
                margin-top: 0;
                font-size: 1.3em;
            }
            h5 {
                font-size: 1em;
            }
        }
    }
    .team-cell-info {
        font-size: 1em;
        @media only screen and (max-width: ${({ theme }) => theme.contentWidth}) {
            font-size: 0.8em;
        }
    }

    .team-cell-links {
        padding-top: 10px;
        font-size: 30px;
        line-height: 30px;
        svg {
            fill: ${({ theme }) => theme.altColor};
        }
    }

    // This is a duplicate of the css style in ./static-html.jsx
    // as it is being overridden by the css in ./markdown.jsx
    p {
        margin: 0.75em 0;
        line-height: 1.2em;
        text-align: justify;
        font-size: 1.2rem;
        font-weight: 200;
        @media only screen and (max-width: ${({ theme }) => theme.contentWidth}) {
            text-align: center;
        }
    }
`;
