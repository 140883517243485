import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled, { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";

import StaticHtml from "../components/common/static-html";
import CenterFrame from "../components/common/center-frame";
import Markdown from "../components/common/markdown";
import LoadingPane from "../components/common/loading-pane";
import TeamCell from "../components/about/team-cell";
import DefaultTemplate from "../templates/default-template";
import theme from "../utils/theme";
import { useLocationInitialization } from "../utils/hooks";

import bottomBackgroundImage from "../images/css/calendly-bottom-curve.svg";

const About = ({ className, data, location, pageContext: { locale } }) => {
    const [dato, setDato] = useState(null);
    const [i18nData, setI18nData] = useState(null);

    useLocationInitialization(location);

    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        setDato(data.allDatoCmsAboutPage.nodes[0]);
        // eslint-disable-next-line react/prop-types
        setI18nData(data.allDatoCmsI18N.nodes[0]);
    }, [data]);

    return (
        <>
            {i18nData && (
                <DefaultTemplate
                    i18nData={i18nData}
                    locale={locale}
                    pathname={location.pathname}
                    title={i18nData.footerAbout}
                    isHomepage
                >
                    <ThemeProvider theme={theme}>
                        {dato ? (
                            <StaticHtml className={className} scrollTop>
                                <CenterFrame className="textual">
                                    <StaticHtml className="about-head">
                                        <h1>{dato.title}</h1>
                                        <Markdown value={dato.headline} />
                                    </StaticHtml>
                                </CenterFrame>

                                <CenterFrame className="team">
                                    <h2>{dato.teamTitle}</h2>
                                    {dato.team.map((member) => (
                                        <TeamCell member={member} key={member.id} />
                                    ))}
                                </CenterFrame>
                            </StaticHtml>
                        ) : (
                            <LoadingPane />
                        )}
                    </ThemeProvider>
                </DefaultTemplate>
            )}
        </>
    );
};

About.propTypes = {
    className: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.any.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,
};

export default styled(About)`
    .team {
        .inner {
            padding-top: 50px;
            padding-bottom: 60px;
        }
        h2 {
            font-size: 2em;
        }
        @media only screen and (max-width: ${theme.contentWidth}) {
            h2 {
                text-align: center;
                font-size: 1.6em;
            }
        }
    }
    .textual {
        position: relative;
        background: ${theme.mainColor};
        &:after {
            position: absolute;
            content: " ";
            width: 100vw;
            z-index: 0;
            background-size: 100% 2rem;
            background-repeat: no-repeat;
            background-image: url(${bottomBackgroundImage});
            background-position: bottom right;
            bottom: -2rem;
            height: 2rem;
        }
    }
    .about-head {
        color: white;
        padding: 40px 20px 0 20px;
        text-align: center;
        h1 {
            color: white;
            font-size: 3em;
        }
        .about-head-in {
            margin: 0 auto;
            width: auto;
            padding: 0 20px;
            color: white;
        }
    }

    .about-center {
        background-color: #fff;
        padding: 40px 0;
    }

    @media only screen and (min-width: ${theme.contentWidth}) {
        .textual {
            padding-bottom: 3em;
        }
        .about-head-in {
            width: ${theme.contentWidth};

            & > * {
                padding-right: 50%;
            }

            padding: 0 10px;
        }
    }
`;

export const query = graphql`
    query About($locale: String) {
        allDatoCmsAboutPage(filter: { locale: { eq: $locale } }) {
            nodes {
                headline
                title
                teamTitle
                team {
                    id
                    role
                    fullName
                    summary
                    linkedin
                    image {
                        url
                    }
                }
            }
        }
        allDatoCmsI18N(filter: { locale: { eq: $locale } }) {
            nodes {
                ...HeaderData
                ...FooterData
                ...CookieBannerData
            }
        }
    }
`;
